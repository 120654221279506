import kycApi from "../kyc-api";

const service = {
  /**
   * getRespondentList
   * Get a full list of surveys (may need to add condition with user's company in the future)
   * @returns {Object<Respondent>[]}
   */
  getRespondentList: async () => {
    try {
      return await kycApi
        .get("/respondents")
        .then((data) => {
          console.log(data);
          return data;
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },

  /**
   * getRespondent
   * @param {Object} requestBody
   * @param {string} [requestBody.responseid]
   * @returns {Object<Question>[]}
   */
  getRespondent: async (requestBody) => {
    let params = { id: requestBody.responseid, _limit: 1 };
    try {
      return await kycApi
        .get("/respondents", { params })
        .then((data) => {
          console.log(data);
          return data;
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },

  /**
   * postRespondent
   * @param {Object} requestBody
   * @param {Object} [requestBody.answer]
   * @param {string} [requestBody.survey.id]
   * @returns {Object<Respondent>}
   */
  postRespondent: async (requestBody) => {
    try {
      return await kycApi
        .post("/respondents", requestBody)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          return error;
        });
    } catch (err) {
      return err;
    }
  },

  //   /**
  //    * updateQuestion
  //    * @param {Object} requestBody
  //    * @param {Number} [requestBody.id]
  //    * @param {String} [requestBody.name]
  //    * @param {String} [requestBody.group]
  //    * @param {String} [requestBody.type]
  //    * @param {Object} [requestBody.answer]
  //    * @returns {Object<Question>}
  //    */
  //   updateQuestion: async (requestBody) => {
  //     const id = requestBody.id;
  //     let obj = {
  //       name: requestBody.name,
  //       group: requestBody.group,
  //       type: requestBody.type,
  //       answer: requestBody.answer,
  //     };

  //     try {
  //       return await kycApi
  //         .put(`/questions/${id}`, obj)
  //         .then((data) => {
  //           console.log(data);
  //         })
  //         .catch((error) => {
  //           return error;
  //         });
  //     } catch (err) {
  //       return err;
  //     }
  //   },

  //   /**
  //    * deleteQuestion
  //    * @param {Object} requestBody
  //    * @param {Number} [requestBody.id]
  //    * @returns {Object<Survey>}
  //    */
  //   deleteQuestion: async (requestBody) => {
  //     try {
  //       const id = requestBody.id;

  //       return await kycApi
  //         .delete(`/questions/${id}`)
  //         .then((data) => {
  //           console.log(data);
  //         })
  //         .catch((error) => {
  //           return error;
  //         });
  //     } catch (err) {
  //       return err;
  //     }
  //   },
};

export default service;
