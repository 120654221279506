<template>
  <div class="grid surveyform-wrap">
    <div class="col-12">
      <div class="card">
        <ProgressSpinner v-if="false === ready && false == sent" style="width: 50px; height: 50px" stroke-width="8" fill="#EEEEEE" />
        <label v-if="true === ready && true == sent"
          >Thank you for your response. Please view your response by clicking <a class="link" @click="viewResponseForm">link</a></label
        >
        <div v-if="true === ready && false == sent" class="survey-content" style="width: 100%">
          <h2>{{ survey.name }}</h2>
          <div class="p-fluid formgrid grid">
            <div v-for="group of survey.groups" :key="group" class="group-wrap field col-12" v-show="currentGroup == group.index">
              <h4>{{ parseProfile(group.name) }}</h4>
              <div v-for="question of group.questions" :key="question" class="field col-12">
                <label v-if="question.appear && question.test != 'investKnowledge'">{{ parseProfile(question.name) }}</label>
                <div v-if="!question.hidden && question.test != 'investKnowledge'">
                  <!-- // question.hidden is for showing msg and store hidden form answer -->
                  <!-- // question.test != 'investKnowledge' is excluding test questions, handle in test group separately -->
                  <!-- Short Question -->
                  <div
                    v-if="
                      question.answer?.params?.filter((e) => e.key === 'calculator').length > 0 &&
                      question.answer?.params?.filter((e) => e.key === 'calculator')[0].value == 'expense'
                    "
                  >
                    <!--  // handle custom question UI, calculator.expense -->
                    <RadioButton name="city" :value="true" v-model="calculator.expense.directInput" />
                    <label class="col-12 mb-2 md:col-4 md:mb-0">直接輸入總額</label>
                  </div>
                  <InputText
                    v-if="question.type == 'Short Question' && question.appear"
                    v-model="question.response"
                    :type="question.answer.inputType"
                    :class="{
                      invalid: (question.response == null || question.response?.length == 0 || paramInvalid.includes(question.id)) && submit,
                    }"
                    @change="checkChange(question)"
                  />
                  <div
                    v-if="
                      question.answer?.params?.filter((e) => e.key === 'calculator').length > 0 &&
                      question.answer?.params?.filter((e) => e.key === 'calculator')[0].value == 'expense'
                    "
                  >
                    <!--  // handle custom question UI, calculator.expense -->
                    <RadioButton name="city" :value="false" v-model="calculator.expense.directInput" />
                    <label class="col-12 mb-2 md:col-4 md:mb-0">按開支類別計算，由系統計算每月總額</label>

                    <Panel v-show="!calculator.expense.directInput" header="類別包括：">
                      <div v-for="(item, name) of calculator.expense.breakdown" :key="name" class="field grid">
                        <label class="col-12 mb-2 md:col-4 md:mb-0">{{ item.label }}</label>
                        <div class="col-12 md:col-8">
                          <InputNumber v-model="item.value" mode="currency" min="0" currency="HKD" locale="en-HK" />
                        </div>
                      </div>
                      <div class="field grid">
                        <label class="col-12 mb-2 md:col-4 md:mb-0">總額</label>
                        <div class="col-12 md:col-8">
                          {{
                            new Intl.NumberFormat("en-HK", {
                              style: "currency",
                              currency: "HKD",
                            }).format(calculatorExpenseTotal)
                          }}
                        </div>
                      </div>
                    </Panel>
                  </div>

                  <!-- Long Question -->

                  <Textarea
                    v-if="question.type == 'Long Question' && question.appear"
                    v-model="question.response"
                    :type="question.answer.inputType"
                    rows="4"
                    :class="{
                      invalid: (question.response == null || question.response?.length == 0 || paramInvalid.includes(question.id)) && submit,
                    }"
                    @change="checkChange(question)"
                  />

                  <div
                    v-if="
                      question.type == 'Single Choice Question' &&
                      question.appear &&
                      question.answer?.params?.filter((e) => e.key === 'game').length > 0 &&
                      question.answer?.params?.filter((e) => e.key === 'game')[0].value == 'draw100'
                    "
                    class="game draw100"
                  >
                    <!--  // handle custom question UI, game.draw100 -->
                    <div class="amount">
                      初始本金：{{
                        new Intl.NumberFormat("en-HK", {
                          style: "currency",
                          currency: "HKD",
                        }).format(game.draw100.amount)
                      }}
                      <span
                        :class="{
                          win: game.draw100.count > 0 && game.draw100.history[game.draw100.count - 1].drawn >= 0,
                          lose: game.draw100.count > 0 && game.draw100.history[game.draw100.count - 1].drawn < 0,
                        }"
                        >{{ game.draw100.count > 0 ? "(" + game.draw100.history[game.draw100.count - 1].drawn + ")" : "" }}</span
                      >
                    </div>
                    <div class="amount">按鈕的機會：{{ game.draw100.drawLimit - game.draw100.count }}</div>
                    <div v-for="choice of question.answer.options" :key="choice" class="field-radiobutton">
                      <Button
                        v-model="question.response"
                        :value="choice.choice"
                        :label="choice.choice"
                        :class="{
                          invalid: question.response == null && submit,
                        }"
                        @click="draw100Click(choice)"
                      />
                    </div>
                  </div>
                  <!-- Single Choice Question -->
                  <div v-else-if="question.type == 'Single Choice Question' && question.appear">
                    <div v-for="choice of question.answer.options" :key="choice" class="field-radiobutton col-6">
                      <RadioButton
                        v-model="question.response"
                        :value="choice.choice"
                        :class="{
                          invalid: question.response == null && submit,
                        }"
                        @change="checkChange(question)"
                      />
                      <label>{{ choice.choice }}</label>
                    </div>
                  </div>

                  <!-- Multiple Choice Question -->
                  <div v-if="question.type == 'Multiple Choice Question' && question.appear">
                    <MultiSelect
                      v-model="question.response"
                      :options="question.answer.options"
                      placeholder="Select Choice"
                      :class="{
                        invalid: question.response == null && submit,
                      }"
                      @change="checkChange(question)"
                    />
                  </div>
                  <div v-if="(question.response == null || question.response?.length == 0) && submit">
                    <small class="p-invalid-label">This field is required.</small>
                  </div>
                  <div v-if="paramInvalid.includes(question.id)">
                    <small class="p-invalid-label">The input answer is out of range</small>
                  </div>
                </div>
              </div>
              <div v-if="group.test == 'investKnowledge'" class="group-test">
                <!-- // group test.investKnowledge -->
                <div v-for="question of test.investKnowledge.questions" :key="question" class="field col-12">
                  <label v-if="question.appear">{{ parseProfile(question.name) }}</label>

                  <!-- Single Choice Question -->
                  <div if="question.type == 'Single Choice Question' && question.appear">
                    <div v-for="choice of question.answer.options" :key="choice" class="field-radiobutton col-6">
                      <RadioButton
                        v-model="question.response"
                        :value="choice.choice"
                        :class="{
                          invalid: question.response == null && submit,
                        }"
                        :disabled="question.response != null"
                        @change="testCheck(question)"
                      />
                      <label>{{ choice.choice }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="group-nav">
              <Button label="Prev" icon="pi pi-angle-left" iconPos="left" class="p-button-rounded p-button-outlined col-6" @click="groupNav(-1)" />
              <Button label="Next" icon="pi pi-angle-right" iconPos="right" class="p-button-rounded p-button-outlined col-6" @click="groupNav(1)" />
            </div>
          </div>
        </div>
        <div v-if="true === ready && false == sent" class="footer">
          <Button label="Submit" icon="pi pi-check" class="p-button-text" @click="submitResponse" />
        </div>
      </div>
    </div>
  </div>
</template>

//
<script>
import surveyService from "../../service/survey";
import respondentService from "../../service/respondent";

// starting value  1M
//     mean     s.d.
// A: -28233  136613
// B: -31933  384083
// C: 26447   26864
// D: 28449   70168

// Using the last 60 records, A as 3 pts, B 4, C 1, D 2.
// Adding up:
// Most risk averse 60-104
// Risk averse 105-149
// Risk loving 150-194
// Most risk loving 195-240

// {"mean": -28233,"sd":136613,"score":3}
// {"mean": -31933,"sd":384083,"score":4}
// {"mean": 26447,"sd":26864,"score":1}
// {"mean": 28449,"sd":70168,"score":2}
// riskAttitudes

function drawFromPool(pool) {
  return pool[Math.floor(Math.random() * pool.length)];
}
function randn_bm() {
  let u = 0,
    v = 0;
  while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
  while (v === 0) v = Math.random();
  let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
  num = num / 10.0 + 0.5; // Translate to 0 -> 1
  if (num > 1 || num < 0) return randn_bm(); // resample between 0 and 1
  return num;
}

function createListOfNNumbersBetweenAAndB(n, a, b) {
  const listOfN = Array(...new Array(n));
  return listOfN.map(() => randn_bm() * (b - a) + a);
}

function computeMeanSdAndItervalRangeMinMax(list) {
  const sum = list.reduce((a, b) => a + b, 0);
  const mean = sum / list.length;
  const sumMinusMean = list.reduce((a, b) => a + (b - mean) * (b - mean), 0);

  return {
    mean: mean,
    sd: Math.sqrt(sumMinusMean / (list.length - 1)),
    range: [Math.min(...list), Math.max(...list)],
  };
}

function transfomListToExactMeanAndSd(list, mean, sd) {
  const current = computeMeanSdAndItervalRangeMinMax(list);
  return list.map((n) => (sd * (n - current.mean)) / current.sd + mean);
}

export default {
  data() {
    return {
      surveyid: this.$route.params.surveyid,
      responseid: null,
      survey: {},
      ready: false,
      answer: null,
      currentGroup: 0,
      validate: {},
      sent: false,
      submit: false,
      paramInvalid: [],
      profile: {},
      calculator: {
        expense: {
          // handle custom question UI, calculator.expense
          directInput: true,
          breakdown: {
            meal: { label: "膳食", value: 0 },
            transportation: { label: "交通", value: 0 },
            household: {
              label: "家居 (例如：租金/樓宇按揭供款、管理費、水電煤、流動電話服務、上網等)",
              value: 0,
            },
            shopping: { label: "購物 (例如：衣服、日常用品等)", value: 0 },
            healthAndBeauty: {
              label: "健康美容(例如：護膚品、化妝品、藥物等)",
              value: 0,
            },
            lifeAndLeisure: { label: "生活消閑(例如：娛樂、旅遊等)", value: 0 },
            insurance: { label: "保險", value: 0 },
            financialSupportToFamily: { label: "為家人提供經濟支援", value: 0 },
            other: { label: "其他", value: 0 },
          },
        },
      },
      test: {
        investKnowledge: {
          // handle custom question UI, test.investKnowledge
          level: {
            // question pool
            1: {
              questions: [],
            },
          },
          lastSequence: 0,
          questions: [], // picked questions
          correct: 0, // count correct
          incorrect: 0, // count incorrect
          min: 1, // min level
          max: 4, // max level
          current: 1, // current level
        },
      },
      game: {
        draw100: {
          drawLimit: 100,
          count: 0,
          history: [],
          amount: 1000000,
          riskAttitudes: 0,
          option: [],
        },
      },
    };
  },
  computed: {
    calculatorExpenseTotal() {
      return Object.values(this.calculator.expense.breakdown).reduce(
        (previousValue, currentValue) => previousValue + Number(currentValue.value), // force it to number, sometimes input value return text type even input is a number
        0
      );
    },
  },

  mounted() {
    this.getSurvey();
    this.paramInvalid = [];
    console.log(this.$router);
  },

  methods: {
    buildPool(mean = -2800, sd = 14000, draws = 10000, rangeMin = -8000, rangeMax = 10000) {
      var list = createListOfNNumbersBetweenAAndB(draws, rangeMin, rangeMax);
      var newList = transfomListToExactMeanAndSd(list, mean, sd);

      // console.log("transformed list, mean and sd", newList, computeMeanSdAndItervalRangeMinMax(newList));
      // console.log("original list, mean and sd", list, computeMeanSdAndItervalRangeMinMax(list));

      // test draw
      // var drawn = [];
      // for (let i = 0; i < 100; i++) {
      //   drawn.push(drawFromPool(newList));
      // }
      // console.log(drawn);

      // var sdResult = [0, 0, 0, 0, 0, 0]; //less than -2sd, -1sd, mean, +1sd, +2sd, +3sd
      // for (let i = 0; i < newList.length; i++) {
      //   if (newList[i] < mean - 2 * sd) {
      //     sdResult[0]++;
      //   } else if (newList[i] < mean - sd) {
      //     sdResult[1]++;
      //   } else if (newList[i] < mean) {
      //     sdResult[2]++;
      //   } else if (newList[i] < mean + sd) {
      //     sdResult[3]++;
      //   } else if (newList[i] < mean + 2 * sd) {
      //     sdResult[4]++;
      //   } else if (newList[i] < mean + 3 * sd) {
      //     sdResult[5]++;
      //   }
      // }
      // console.log(sdResult);

      // console.log(list.reduce((prev, next) => prev + next, 0) / list.length);
      // console.log(newList.reduce((prev, next) => prev + next, 0) / newList.length);
      return newList;
    },
    draw100Click(choice) {
      // console.log(choice.attribute);
      if (this.game.draw100.count >= this.game.draw100.drawLimit) {
        return;
      }
      let gameOption = this.game.draw100.option.find((element) => element.choice == choice.choice);
      let drawnNumber = drawFromPool(gameOption.pool);
      // let amountBefore = this.game.draw100.amount;
      this.game.draw100.amount += drawnNumber;
      this.game.draw100.count++;
      this.game.draw100.history.push({ choice: gameOption.choice, score: gameOption.score, drawn: drawnNumber });
      if (this.game.draw100.count > 40) {
        this.game.draw100.riskAttitudes += gameOption.score;
        // update profile riskAttitudes
        this.profile["riskAttitudes"] = this.game.draw100.riskAttitudes;
      }
      // console.log(this.game.draw100.riskAttitudes);
      // console.log(this.game.draw100.count, amountBefore, drawnNumber, this.game.draw100.amount);
      // console.log("[%d] amount: %d (%d) -> %d", this.game.draw100.count, amountBefore, drawnNumber, this.game.draw100.amount);
    },
    testPickQuestion() {
      if (this.test.investKnowledge.current > 0 && this.test.investKnowledge.current < 4) {
        let remainQ = this.test.investKnowledge.level[this.test.investKnowledge.current]?.questions.filter(
          (e) => e.sequence > this.test.investKnowledge.lastSequence
        );
        console.log(remainQ);
        if (remainQ && remainQ.length > 0) {
          // this.test.investKnowledge.lastSequence = remainQ[0].sequence;
          if (!this.test.investKnowledge.questions.includes(remainQ[0])) {
            this.test.investKnowledge.questions.push(remainQ[0]);
          }
        } else {
          // TODO: end game, case lv 1 no more Q
          return true; // end test
        }
      }
      return false;
    },
    testCheck(question) {
      this.test.investKnowledge.lastSequence = question.sequence;
      question.answer.attribute.input.forEach((inputans) => {
        if (inputans.key == question.response) {
          if (inputans.score > 0) {
            this.test.investKnowledge.correct++;
          } else if (inputans.score < 0) {
            this.test.investKnowledge.incorrect++;
          }
          if (this.test.investKnowledge.correct == 3 && this.test.investKnowledge.incorrect == 0) {
            // consecutively answer 3 questions correctly => lv up
            if (this.test.investKnowledge.current + 1 >= this.test.investKnowledge.max) {
              this.test.investKnowledge.current += 0.5;
            } else {
              this.test.investKnowledge.current++;
              this.test.investKnowledge.correct = 0;
              this.test.investKnowledge.incorrect = 0;
              this.test.investKnowledge.lastSequence = 0;
            }
          }
          if (this.test.investKnowledge.incorrect == 3 && this.test.investKnowledge.correct == 0) {
            // consecutively answer 3 questions incorrectly => lv down
            if (this.test.investKnowledge.current - 1 <= this.test.investKnowledge.min) {
              this.test.investKnowledge.current -= 0.5;
            } else {
              this.test.investKnowledge.current--;
              this.test.investKnowledge.correct = 0;
              this.test.investKnowledge.incorrect = 0;
              this.test.investKnowledge.lastSequence = 0;
            }
          }
        }
      });
      // TODO: correct/incorrect count
      // TODO: calc level change
      // TODO: update min/max level, lv up => min_lv = old_lv
      // TODO: end game, case lv 3 no more Q (did not trigger lv down)
      // TODO: end game, case lv rebound, bound by min/max lv
      let endTest = this.testPickQuestion();
      if (endTest) {
        this.profile["investKnowledgeTestLevel"] = this.test.investKnowledge.current;
        console.log(endTest, this.test.investKnowledge.current);
      }
    },
    parseProfile(msg) {
      let parsed = msg;
      let keys = parsed.match(/{{(.+?)}}/g);
      // console.log(keys);
      for (let i in keys) {
        // console.log(keys[i], this.profile[keys[i].replace("{{", "").replace("}}", "")]);
        parsed = parsed.replace(keys[i], this.profile[keys[i].replace("{{", "").replace("}}", "")]);
      }
      // console.log(parsed);

      return parsed;
    },
    getSurvey() {
      this.submit = false;
      let obj = { surveyid: this.surveyid };
      //get survey
      surveyService.getSurvey(obj).then((result) => {
        this.survey = { id: result[0].id, name: result[0].name };
        this.survey.groups = [];

        result[0].groups.forEach((group) => {
          let obj = { id: group.id, name: group.name, questions: [], sequence: group.sequence };
          this.survey.groups.push(obj);
        });

        result[0].questions.forEach((question) => {
          // include attribute variable in profile object
          if (question.key == "profile") {
            this.profile[question.answer.attribute.variable] = 0;
            let init = question.answer.params?.find((element) => element.key == "init");
            if (init) {
              this.profile[question.answer.attribute.variable] = Number(init.value);
              question.response = init.value;
            }
            let hidden = question.answer.params?.find((element) => element.key == "hidden");
            if (hidden?.value > 0) {
              question.hidden = true;
            }
          }

          // reset the format for multiple choice question, easier for multiselect
          if (question.type == "Multiple Choice Question") {
            let choices = [];
            question.answer.options?.forEach((option) => {
              // set option.attribute.score
              // option.attribute = { score: question.answer.attribute.input.find((element) => element.key == option.choice).score };
              choices.push(option.choice);
            });
            question.answer.options = choices;
          }
          // Force to input numbers depend on the dataType
          else if (question.type == "Long Question" || question.type == "Short Question") {
            if ("dataType" in question.answer) {
              let type = question.answer.dataType == "String" ? "text" : "number";
              question.answer.inputType = type;
            }
          } else if (question.type == "Single Choice Question") {
            question.answer.options?.forEach((option) => {
              if (
                question.answer?.params?.filter((e) => e.key === "game").length > 0 &&
                question.answer?.params?.filter((e) => e.key === "game")[0].value == "draw100"
              ) {
                // console.log(option.choice, question.answer.attribute.input.find((element) => element.key == option.choice).score);
                option.attribute = Object.assign({}, JSON.parse(question.answer.attribute.input.find((element) => element.key == option.choice).score));
                // console.log(option.attribute);
                let obj = { choice: option.choice, mean: option.attribute.mean, sd: option.attribute.sd, score: option.attribute.score };
                let pool = this.buildPool(obj.mean, obj.sd);
                obj.pool = pool;
                this.game.draw100.option.push(obj);
              } else {
                option.attribute = { score: question.answer.attribute?.input?.find((element) => element.key == option.choice).score };
              }
            });
          }

          // handle the question appear condition
          // add an "appear" variable to control whether the question should show or not
          // in this case i suppose there is a OR relationship between multiple conditions
          question.appear = true;
          if (question.condition != null) {
            question.condition.forEach((condition) => {
              if (this.profile[condition.variable] <= parseInt(condition.value)) {
                question.appear = false;
              }
            });
          }

          // handle custom question UI, test.investKnowledge
          let testInvestKnowledge = question.answer.params?.find((element) => element.key == "test" && element.value == "investKnowledge");
          let testLevel = question.answer.params?.find((element) => element.key == "level");
          if (testInvestKnowledge && testLevel) {
            let lv = testLevel.value;
            if (!this.test.investKnowledge.level[lv]) {
              this.test.investKnowledge.level[lv] = { questions: [] };
            }
            this.test.investKnowledge.level[lv].questions.push(question);
            question.test = "investKnowledge";
          }

          // set response to store user ans
          question.response = null;
          //push question to corresponding group in this.survey
          this.survey.groups.forEach((group) => {
            if (group.id == question.group) {
              group.questions.push(question);
              if (question.answer.attribute?.variable == "investKnowledgeTestLevel") {
                group.test = "investKnowledge";
              }
            }
          });
        });

        // sort the sequence of group
        this.survey.groups.sort((a, b) => {
          return a.sequence - b.sequence;
        });

        // sort the sequence of question
        this.survey.groups.forEach((group) => {
          group.questions.sort((a, b) => {
            return a.sequence - b.sequence;
          });
        });

        // handle custom question UI, test.investKnowledge
        // sort the sequence of question
        Object.keys(this.test.investKnowledge.level).forEach((lv) => {
          this.test.investKnowledge.level[lv].questions.sort((a, b) => {
            return a.sequence - b.sequence;
          });
        });

        // assign index
        let qIndex = 0;
        let gIndex = 0;
        this.survey.groups.forEach((group) => {
          group.index = gIndex++;
          group.questions.forEach((q) => {
            q.index = qIndex++;
          });
        });
        console.log(this.survey);
        this.ready = true;
      });
    },
    groupNav(step) {
      this.currentGroup += step;
      if (this.currentGroup < 0) {
        this.currentGroup = 0;
      }
      if (this.currentGroup > this.survey.groups.length - 1) {
        this.currentGroup = this.survey.groups.length - 1;
      }
      let g = this.survey.groups.find((e) => e.index == this.currentGroup);
      if (g.test) {
        console.log(g);
        this.test.investKnowledge.current = this.profile["investKnowledgeSelfLevel"];
        this.testPickQuestion();
      }
    },

    calculateProfile(question) {
      console.log(question);
      // check if the question contains attribute
      if (question.key == "profile") {
        // get the attirbute variable
        let variable = question.answer.attribute.variable;
        // reset the variable store as 0 to recalculate it again
        this.profile[variable] = 0;
        this.survey.groups.forEach((group) => {
          group.questions.forEach((q) => {
            if (q.key == "profile") {
              // get the question having the same vairable, and add score to this.profile
              if (q.answer.attribute.variable == variable) {
                if (q.type == "Multiple Choice Question") {
                  let score = 0;
                  q.response?.forEach((response) => {
                    q.answer.attribute.input.forEach((inputans) => {
                      if (response == inputans.key) {
                        let pick = q.answer.params?.find((element) => element.key == "pick");
                        if (pick && pick.value == "highest") {
                          score = Math.max(score, parseInt(inputans.score));
                        } else {
                          score += parseInt(inputans.score);
                        }
                      }
                    });
                  });
                  this.profile[variable] += score;
                } else if (q.type == "Single Choice Question") {
                  let score = 0;
                  q.answer.attribute.input.forEach((inputans) => {
                    // for single mc
                    if (q.type == "Single Choice Question") {
                      // handle custom question UI, game.draw100
                      if (
                        q.answer?.params?.filter((e) => e.key === "game").length > 0 &&
                        q.answer?.params?.filter((e) => e.key === "game")[0].value == "draw100"
                      ) {
                        this.profile[q.answer.attribute.variable] = this.game.draw100.riskAttitudes;
                      }
                    }
                    if (q.response == inputans.key) {
                      score += parseInt(inputans.score);
                    }
                  });
                  this.profile[variable] += score;
                } else {
                  q.answer.attribute.input.forEach((inputans) => {
                    if (inputans.key == q.response) {
                      this.profile[variable] += parseInt(inputans.score);
                    }
                  });
                }
              }
            }
          });
        });
      }
      console.log(this.profile);
    },

    updateQuestionAppear(question) {
      // First: go to some question directly based on the current ans
      if ("condition" in question.answer) {
        let targetQ;
        question.answer.condition.forEach((condition) => {
          if (condition.key == question.response) {
            targetQ = condition.question;
          }
        });

        this.survey.groups.forEach((group) => {
          if (group.id == question.group) {
            group.questions.forEach((q) => {
              q.appear = false;
              if (q.sequence <= question.sequence || q.sequence >= targetQ.sequence) {
                q.appear = true;
              }
              //clear response
              if (!q.appear) {
                q.response = null;
              }
            });
          }
        });
      }

      // Second: question.appear amendment based on profile values
      this.survey.groups.forEach((group) => {
        group.questions.forEach((q) => {
          if (q["condition"] != null) {
            q.appear = true;
            q.condition.forEach((condition) => {
              if (this.profile[condition.variable] <= parseInt(condition.value)) {
                q.appear = false;
              }
            });

            //clear response
            if (!q.appear) {
              q.response = null;
            }
          }
        });
      });
    },

    checkChange(question) {
      this.calculateProfile(question);
      // calculate for all hidden questions.
      this.survey.groups.forEach((group) => {
        group.questions.forEach((checkQ) => {
          if (checkQ.hidden) {
            this.calculateProfile(checkQ);
          }
          if (checkQ.answer.attribute?.variable == "investKnowledgeSelfLevel") {
            let init = checkQ.answer.params?.find((element) => element.key == "init");
            if (init) {
              this.profile[checkQ.answer.attribute.variable] = Number(init.value);
              checkQ.response = init.value;
            }
            checkQ.answer.attribute?.input?.forEach((inputans) => {
              let k = inputans.key.split(" ");
              if (k && k.length > 1) {
                let profileVal = this.profile[k[0]];
                if (profileVal >= Number(k[1])) {
                  this.profile[checkQ.answer.attribute.variable] = inputans.score;
                }
              }
              // let investKnowledgeSelf = this.profile["investKnowledgeSelf"];
            });
          }
        });
      });
      this.updateQuestionAppear(question);
    },

    validateResponse() {
      let passValidate = true;
      this.survey.groups.forEach((group) => {
        group.questions.forEach((question) => {
          // only consider the questions that appear
          if (question.appear) {
            // handle custom question UI, calculator.expense
            if (
              question.answer?.params?.filter((e) => e.key === "calculator").length > 0 &&
              question.answer?.params?.filter((e) => e.key === "calculator")[0].value == "expense" &&
              !this.calculator.expense.directInput
            ) {
              question.response = this.calculatorExpenseTotal;
            }
            // handle custom question UI, game.draw100
            if (
              question.answer?.params?.filter((e) => e.key === "game").length > 0 &&
              question.answer?.params?.filter((e) => e.key === "game")[0].value == "draw100"
            ) {
              question.response = this.game.draw100.riskAttitudes;
            }
            // handle custom question UI, test.investKnowledge
            if (question.answer?.attribute?.variable === "investKnowledgeTestLevel") {
              question.response = this.test.investKnowledge.current;
            }
            let testQ = question.answer?.params?.find((e) => e.key === "test" && e.value === "investKnowledge");
            if (testQ) {
              // test question not applicable may not used
            } else if (question.response == null) {
              passValidate = false;
            } else if (question.type == "Long Question" || question.type == "Short Question") {
              if (question.response.length == 0) {
                passValidate = false;
              }
              // check parameter
              if ("params" in question.answer) {
                question.answer.params.forEach((params) => {
                  if (params.key == "max") {
                    if (question.response > parseInt(params.value)) {
                      console.log(question.response, params.value);
                      passValidate = false;
                      this.paramInvalid.push(question.id);
                    }
                  }
                  if (params.key == "min") {
                    if (question.response < parseInt(params.value)) {
                      console.log(question.response, params.value);

                      passValidate = false;
                      this.paramInvalid.push(question.id);
                    }
                  }
                });
              }
            }
          }
        });
      });
      console.log(passValidate);
      return passValidate;
    },

    submitResponse() {
      console.log(this.survey);
      this.submit = true;
      this.paramInvalid = [];
      let wholeProfile = this.profile;
      if (!this.validateResponse()) {
        return;
      } else {
        this.ready = false;
        let answer = [];

        this.survey.groups.forEach((group) => {
          group.questions.forEach((question) => {
            let obj = {
              question: question.id,
              group: question.group,
              answer: question.response,
            };
            answer.push(obj);
          });
        });

        let obj = { survey: this.surveyid, answer: answer, profile: wholeProfile };
        respondentService.postRespondent(obj).then((result) => {
          this.responseid = result.id;
          this.sent = true;
          this.ready = true;
        });
      }
    },

    viewResponseForm() {
      this.$router.replace("/response/" + this.responseid);
    },
  },
};
</script>

//
<style scoped lang="scss">
.surveyform-wrap {
  .col-12 {
    ::v-deep(.card) {
      display: flex;
      align-items: center;
      flex-direction: column;
      min-height: 35rem;
      justify-content: center;
      .link {
        cursor: pointer;
      }
      .survey-content {
        width: 100% !important;
        .group-wrap {
          min-height: 25rem;
        }
        .field {
          .p-invalid-label {
            color: red;
          }

          .p-inputtextarea,
          .p-multiselect,
          .p-inputtext {
            &.invalid {
              border-color: red;
            }
          }

          .field-radiobutton {
            .p-radiobutton {
              &.invalid {
                .p-radiobutton-box {
                  border-color: red;
                }
              }
            }
          }
        }
        .group-nav {
          display: grid;
          grid-template-columns: 50% 50%;
          column-gap: 1rem;
          margin: 0 auto;
          .p-button {
            width: 10rem;
            padding: 0.5rem;
          }
        }
        .game.draw100 {
          .amount {
            .win {
              color: green;
            }
            .lose {
              color: red;
            }
          }
        }
      }
    }
  }
}
</style>
